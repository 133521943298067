import { render, staticRenderFns } from "./titlebar.vue?vue&type=template&id=8896b15c&scoped=true&"
import script from "./titlebar.vue?vue&type=script&lang=js&"
export * from "./titlebar.vue?vue&type=script&lang=js&"
import style0 from "./titlebar.vue?vue&type=style&index=0&id=8896b15c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8896b15c",
  null
  
)

export default component.exports